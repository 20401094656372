/* src/App.css */

/* Paleta de Cores */
:root {
  --primary-color: #5A7304; /* Cor da logo e dos botões ajustada para maior contraste */
  --secondary-color: #F0F4C3; /* Tom claro complementar */
  --accent-color: #9E9D24; /* Cor de destaque */
  --text-color: #333; /* Cor do texto padrão */
  --background-color: #FFFFFF; /* Fundo padrão */
}

/* Estilos Globais */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6; /* Melhor espaçamento entre linhas */
}

/* Header */
.header {
  background-color: var(--background-color); /* Cor igual ao fundo da página */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); /* Leve sombra para destaque */
}

.logo-img {
  height: 40px;
  width: auto;
  transition: transform 0.3s ease;
}

.header:hover .logo-img {
  transform: scale(1.1);
}

.navigation a {
  color: var(--primary-color); /* Cor dos links igual à da logo */
  text-decoration: none;
  font-weight: bold;
  margin: 0 8px;
  transition: color 0.3s, transform 0.3s;
}

.navigation a:hover {
  color: var(--accent-color);
  transform: translateY(-2px); /* Leve movimento para o hover */
}

/* Footer */
.footer {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 16px;
  text-align: center;
}

/* Estilos do SearchBar */
.search-bar {
  position: relative;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  max-width: 1100px;
  width: 90%;
  margin: 20px auto 0;
  display: flex;
  align-items: center; /* Alinha o conteúdo verticalmente ao centro */
  gap: 10px; /* Espaçamento entre os campos */
}

.search-bar .form-floating {
  flex: 1; /* Faz os campos de busca ocuparem o espaço restante */
}

.search-bar .form-control {
  padding-left: 2rem;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 1px solid var(--primary-color);
}

.search-bar .form-label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--primary-color);
  font-size: 1rem;
}

.search-bar .btn {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  flex-shrink: 0; /* Evita que o botão encolha */
}

.search-bar .btn:hover {
  background-color: var(--accent-color) !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); /* Leve movimento para hover */
}

/* Ajustes de estilo para a Seção de Estatísticas */
.statistics-section {
  padding: 40px 0;
  background-color: #f8f9fa;
}

.statistics-section .col-md-4 {
  margin-bottom: 20px;
}

.statistics-section h3 {
  font-size: 36px;
  font-weight: bold;
  margin-top: 10px;
  color: var(--primary-color);
}

.statistics-section p {
  font-size: 16px;
  color: var(--text-color);
}

/* Estilos da Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://picsum.photos/1920/1080?random=15');
  background-size: cover;
  background-position: center;
  filter: brightness(60%);
}

.hero-text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  text-align: center;
  z-index: 10;
  padding: 16px;
  animation: fadeIn 0.5s ease-out; /* Ajuste na animação para suavidade */
}

.hero-text h1 {
  color: var(--secondary-color);
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  margin: 0;
}

/* Estilo Consistente para Botões */
.btn-primary {
  border-radius: 4px;
  padding: 8px 16px;
  background-color: #5A7304 !important; /* Ajustado para maior contraste */
  color: #fff !important;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: var(--accent-color) !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); /* Efeito de hover mais visível */
}

/* Animação ao Rolamento */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.6s forwards;
}

/* Estilos do Card de Viagens */
.trip-card {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  max-width: 350px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  padding-bottom: 16px; /* Espaçamento extra na parte inferior */
}

.trip-card:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.trip-image-container {
  position: relative;
}

.trip-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

/* Badge de Destaque */
.destaque-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--accent-color);
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  font-weight: bold;
  z-index: 5;
  opacity: 0.9;
}

.trip-content {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid #eee;
}

.trip-info {
  flex-grow: 1;
}

.trip-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 8px; /* Espaçamento acima do preço para separação visual */
}

.trip-price .price {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-color);
  margin-top: 4px;
  }

.price {
  font-size: 1.0rem; /* Aumenta o tamanho da fonte do valor */
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 4px; /* Espaçamento abaixo do preço */
}

.trip-card.agency-info {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.agency-logo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

/* Botão "Ver Mais" no Card de Viagens */
.trip-button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px; /* Espaçamento entre o botão e o conteúdo */
  align-self: center; /* Centraliza o botão horizontalmente */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.trip-button:hover {
  background-color: var(--accent-color);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); /* Efeito de hover mais perceptível */
}

/* Ajuste de estilos para títulos de seção */
section h2 {
  font-size: 26px;
  font-weight: bold;
  color: var(--primary-color);
  text-align: left;
  padding-left: 16px;
  margin: 0;
  margin-bottom: 8px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.upcoming-trips-section .section-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 0;
  max-width: 1100px;
  margin: 0 auto;
}

.sort-options {
  margin-left: auto;
}

/* Ajuste de espaçamento entre as seções */
section {
  padding: 8px 0;
  margin-bottom: 4px;
}

/* Definindo o layout da grid para os Destinos Populares */
.destinations-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr; /* Uma coluna larga e duas menores */
  grid-template-rows: repeat(2, 150px); /* Duas linhas de 150px */
  gap: 10px;
  max-width: 1100px;
  margin: 0 auto;
}

/* Primeiro card, grande, ocupa toda a altura */
.destination-card:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1 / span 2; /* Ocupa duas linhas de altura */
  height: 100%; /* Preenche toda a altura da grid */
}

/* Segundo e terceiro cards, pequenos */
.destination-card:nth-child(2),
.destination-card:nth-child(3) {
  grid-column: span 1;
  grid-row: span 1;
  height: 100%; /* Mantém a altura da grid */
}

/* Quarto card, médio, ocupa o espaço abaixo dos dois pequenos */
.destination-card:nth-child(4) {
  grid-column: 2 / span 2; /* Ocupa duas colunas */
  grid-row: 2 / 3; /* Ocupa apenas a segunda linha */
  height: 100%; /* Preenche toda a altura */
}

/* Estilos gerais para os cards */
.destination-card {
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  padding: 10px;
}

.destination-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.destination-name {
  position: relative;
  z-index: 2;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 2px black;
}

/* Categorias, Destinos, and Trips Sections */
.destinations-container::-webkit-scrollbar {
  display: none;
}

/* Ajuste para cards de Categorias menores, com 2 linhas */
.categories-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Exibir 6 cards por linha */
  gap: 12px; /* Espaçamento entre os cards */
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

/* Ajustar para 3 cards por linha */
.trips-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards por linha */
  gap: 20px; /* Aumentar o espaçamento entre os cards */
  max-width: 1100px; /* Garantir que a largura máxima seja respeitada */
  margin: 0 auto; /* Centralizar a grid */
  padding: 16px;
}

/* Para telas menores, ajusta para 2 cards */
@media (max-width: 900px) {
  .trips-container {
    grid-template-columns: repeat(2, 1fr); /* 2 cards por linha em telas menores */
  }
  
  .categories-container {
    grid-template-columns: repeat(3, 1fr); /* 3 cards por linha em telas menores */
  }
}

/* Para smartphones, ajusta para 1 card */
@media (max-width: 600px) {
  .trips-container {
    grid-template-columns: 1fr; /* 1 card por linha em telas muito pequenas */
  }
  
  .categories-container {
    grid-template-columns: repeat(2, 1fr); /* 2 cards por linha em telas pequenas */
  }
}

.categories-container::-webkit-scrollbar {
  display: none;
}

/* Melhorando o estilo dos cards */
.category-card {
  position: relative;
  width: 170px; /* Mantém o novo tamanho */
  height: 85px; /* Altura ajustada */
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex; /* Flexbox para centralização */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  text-align: center; /* Garante o alinhamento do texto no centro */
}

.category-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));
  border-radius: 8px;
}

.category-card span {
  position: relative;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 2px black;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 1;
  text-align: center;
}

/* Estilos adicionais para trip-card */
.trip-card .trip-title {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 4px;
}

.trip-dates {
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 2px;
}

/* Ajuste da seção de descrição */
.trip-content .trip-description {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: #fff;
  background-color: var(--accent-color);
  padding: 2px 6px;
  border-radius: 12px;
  margin-top: 4px;
}

.trip-price .price {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-color);
  margin-top: 4px;
}

.trip-price span {
  font-size: 12px;
  color: #777;
}

.load-more-container {
  text-align: center;
  margin-top: 16px;
}

.load-more-button {
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: var(--accent-color);
}

/* Rating de Estrelas */
.rating {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.stars {
  color: #f8c12d;
  font-size: 14px;
  margin-right: 4px;
}

.rating-score {
  font-size: 12px;
  color: var(--text-color);
}

.agency-name {
  font-size: 14px;
  color: var(--text-color);
  text-align: left !important;
}

/* Skeleton Loader */
.skeleton-card {
  width: 100%;
  height: 150px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media (max-width: 1200px) {
  .destination-card {
    width: calc(50% - 16px); /* Ajuste para 2 cards por linha em telas menores */
  }
}

@media (max-width: 600px) {
  .destination-card {
    width: 100%; /* Ajuste para 1 card por linha em telas pequenas */
  }
}

/* Trip Details Page Styles */
.trip-details-main {
  padding: 20px;
}

.trip-gallery {
  position: relative;
}

.trip-image {
  width: 100%;
  height: auto;
}

.gallery-actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.gallery-actions .btn {
  margin: 0 10px;
}

.trip-details-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.trip-details-info {
  flex: 2;
  padding-right: 20px;
}

.trip-details-info h1, h3 {
  color: var(--primary-color);
}

.trip-details-form {
  flex: 1;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.trip-details-form input, .trip-details-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
}

.passengers {
  display: flex;
  justify-content: space-between;
}

.faq-section {
  padding: 20px;
}

.faq-item {
  padding: 10px 0;
  cursor: pointer;
}

.reviews-section {
  padding: 20px;
}

.review-item {
  margin-bottom: 15px;
}

/* Trip Details Page */
.trip-details-page {
    padding-bottom: 60px;
}

/* Ajustes de layout */
.trip-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.trip-dates {
  font-size: 1rem;
  color: #333;
}

.trip-description,
.trip-schedule,
.trip-departures {
  margin-top: 20px;
}

.trip-card .trip-description p {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
}

ul {
  list-style: none;
  padding-left: 0;
}

ul li {
  font-size: 1rem;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

ul li .me-2 {
  margin-right: 8px;
}

/* Botão flutuante */
.floating-buy-section {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #5A7304;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}

.floating-buy-section .price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.floating-buy-section .btn {
  background-color: #9E9D24;
  color: #fff;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Aumentar o contraste de cores para acessibilidade */
.btn-primary {
  background-color: #5A7304 !important; /* Ajustado para maior contraste */
}

.text-color {
  color: #333 !important; /* Garantindo contraste de texto */
}


a.trip-button {
  text-decoration: none !important;
}