/* src/pages/TripDetails.css */

/* Estilos para a página de detalhes da viagem */
.trip-details-page {
    padding-bottom: 60px;
}

.trip-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Título da Viagem */
.trip-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primary-color);
}

.trip-price-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.trip-price {
    font-size: 1.5rem;
    color: var(--accent-color);
    font-weight: bold;
    margin-bottom: 10px;
}

.trip-price-button .btn {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 8px;
}

.trip-dates {
    font-size: 1rem;
    color: var(--text-color);
}

/* Descrição */
/* Estilos para a página de detalhes da viagem */
.trip-details-page .trip-description {
    margin-top: 20px !important;
    border-radius: 8px !important;
}

.trip-details-page .trip-description h4 {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

.trip-details-page .trip-description p {
    font-size: 1rem !important;
    color: var(--text-color) !important;
    line-height: 1.6 !important;
}

/* Estilo para Programação */
.trip-schedule {
    margin-top: 20px;
}

.trip-schedule h4 {
    font-size: 1.5rem;
    font-weight: bold;
}

.day-program {
    margin-left: 20px;
    margin-top: 10px;
}

.day-program h5 {
    font-size: 1.2rem;
    margin: 0;
    font-weight: bold;
}

.day-program p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.4;
}

.trip-departures, .trip-includes {
    margin-top: 30px;
}

.trip-includes ul, .trip-departures ul {
    list-style: none;
    padding-left: 0;
}

.trip-includes li, .trip-departures li {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.trip-includes li .me-2, .trip-departures li .me-2 {
    margin-right: 8px;
}

.trip-details-page .btn-primary {
    width: 100%;
    max-width: 300px;
    margin-top: 15px;
    background-color: #76860A !important;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
}

/* Botão de compra flutuante */
.floating-card {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 280px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease;
}

.price-label {
    font-size: 1rem;
    color: #555;
    align-self: flex-start;
}

.price-value {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--primary-color);
    margin: 5px 0;
    align-self: flex-start;
}

.installments {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 15px;
    align-self: flex-start;
}

.payment-methods {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    margin-bottom: 15px;
}

.floating-card button {
    background-color: var(--primary-color);
    color: white;
    padding: 12px 20px;
    font-size: 1.1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.floating-card button:hover {
    background-color: var(--accent-color);
    transform: translateY(-3px);
}

.urgency-text {
    font-size: 0.9rem;
    color: #e74c3c;
    font-weight: bold;
    margin-bottom: 15px;
    align-self: flex-start;
}

/* Ajustar o espaçamento entre as seções */
.section-spacing {
    margin-top: 20px;
}

/* Formas de Pagamento */
.payment-options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.payment-icons {
    display: flex;
    gap: 10px;
}

/* FAQ */
.faq-section {
    margin-top: 40px;
}

.faq-item {
    margin-bottom: 20px;
}

.faq-item strong {
    display: block;
    margin-bottom: 8px;
    font-size: 1.2rem;
    color: var(--primary-color);
}

/* Agência Responsável */
.agency-contact {
    margin-top: 40px;
}

.agency-contact p {
    font-size: 1rem;
    color: var(--text-color);
}

.agency-contact h4 {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--primary-color);
}

/* Responsividade */
@media (max-width: 768px) {
    .floating-card {
        width: 100%;
        bottom: 0;
        right: 0;
        border-radius: 0;
    }
}

/* Estilos para o cartão de partida */
.departure-card {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    max-width: 500px;
}

.departure-card h5 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--primary-color);
}

.departure-address {
    font-size: 0.9rem;
    color: #555; /* Cor mais clara para o endereço */
    margin: 4px 0;
}

.departure-card p {
    margin: 2px 0;
}
