/* src/components/TripDepartureCard.css */

.departure-card {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    max-width: 500px; /* Tamanho máximo para o card */
}

.bus-icon {
    font-size: 30px; /* Tamanho do ícone */
    color: var(--primary-color); /* Mantém a cor do tema */
    margin-right: 12px; /* Espaço entre o ícone e as informações */
}

.departure-info {
    display: flex;
    flex-direction: column; /* Alinhar os textos em coluna */
}

.departure-location {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--primary-color);
    margin: 0; /* Remover o espaçamento padrão */
}

.departure-details {
    font-size: 0.9rem;
    color: #666;
    margin: 0; /* Remover o espaçamento padrão */
}
