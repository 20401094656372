.pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  
  .page-item.active .page-link {
    background-color: var(--primary-color); /* Define a cor ativa */
    border-color: var(--primary-color);
    color: #fff;
  }
  
  .page-item .page-link {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 8px 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .page-item .page-link:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
  
  