.agency-card {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin-bottom: 20px;
}

.agency-photo {
    flex-shrink: 0;
    margin-right: 15px;
}

.agency-photo img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.agency-info {
    flex-grow: 1;
    font-size: 0.95rem;
}

.agency-info h4 {
    margin: 0 0 5px;
    font-size: 1.2rem;
    color: var(--primary-color);
}

.agency-info p {
    margin: 3px 0;
    color: #555;
}

.agency-info a {
    color: var(--primary-color);
    text-decoration: none;
}

.agency-info a:hover {
    text-decoration: underline;
}

.btn-whatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #25D366;
    color: white;
    padding: 10px;
    border-radius: 50%;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.btn-whatsapp:hover {
    background-color: #20b55b;
}
